
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "camera-content-legacy-component",
  components: { Field, ErrorMessage },
  props: {
    cameras: Array,
  },
  data() {
    return {
      cameraDetails: [
        {
          imageOfReceipt: "",
          brand: "",
          value: 0.0 as number,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (this.cameras !== undefined && !this.isEmptyArray(this.cameras)) {
      this.cameraDetails = [...this.cameras] as any;
    }
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    addDynamicField() {
      this.cameraDetails.push({
        imageOfReceipt: "",
        brand: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-camera-" + counter).remove();
    },
    imageSelected(event, position) {
      this.cameraDetails[position].imageOfReceipt = event.target.files;
    },
  },
});
