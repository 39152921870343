
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "laptop-content-legacy-component",
  components: { Field, ErrorMessage },
  props: {
    laptops: Array,
  },
  data() {
    return {
      laptopOrTabletDetails: [
        {
          serialNumber: "",
          imageOfReceipt: "",
          value: 0.0 as number,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (this.laptops !== undefined && !this.isEmptyArray(this.laptops)) {
      this.laptopOrTabletDetails = [...this.laptops] as any;
    }
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    addDynamicField() {
      this.laptopOrTabletDetails.push({
        serialNumber: "",
        imageOfReceipt: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-laptop-" + counter).remove();
    },
    imageSelected(event, position) {
      this.laptopOrTabletDetails[position].imageOfReceipt =
        event.target.files[0];
    },
  },
});
