
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import $ from "jquery";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "jewelry-content-legacy-component",
  components: { Field, ErrorMessage },
  props: {
    jewelries: Array,
  },
  data() {
    return {
      jewelryDetails: [
        {
          imageOfReceipt: "",
          brand: "",
          value: 0.0 as number,
        },
      ],
    };
  },
  setup() {
    const { isEmptyArray } = ResusableFunctions();

    return {
      isEmptyArray,
    };
  },
  created() {
    if (this.jewelries !== undefined && !this.isEmptyArray(this.jewelries)) {
      this.jewelryDetails = [...this.jewelries] as any;
    }
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    addDynamicField() {
      this.jewelryDetails.push({
        imageOfReceipt: "",
        brand: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-jewelry-" + counter).remove();
    },
    imageSelected(event, position) {
      this.jewelryDetails[position].imageOfReceipt = event.target.files[0];
    },
  },
});
